import type { ICSFilter } from '~/types/interfaces/ICSFilter';

/**
 * This function builds the url for either a single car or multiple cars with the appropiate filters set.
 * @param brandId id of brand which cars should be retrieved
 * @param filters single or multiple ICS Filters
 */
export const buildCarsUrl = (brandId?: string | null, filters?: ICSFilter) => {
    const config = useRuntimeConfig();
    const country = useCurrentCountry();

    // Create filter string
    let filterParams = '';
    if (filters && Object.keys(filters)) {
        Object.keys(filters).forEach((key: string, idx) => {
            filterParams += `${idx != 0 ? '&' : ''}${key}=${filters[key as keyof ICSFilter]}`;
        });
    }

    // Specific brand cars
    if (brandId)
        return `${config.public.icsBaseUrl}/service/public/cars/${country.value}/brands/${brandId}/?${filterParams}${config.public.siteMode === 'B2B' ? '&b2b=1' : ''}`;

    // Standard promo
    if (filters && filters.promo)
        return `${config.public.icsBaseUrl}/service/public/car/${country.value}/promo/standard/`;
    // Restposten promo
    if (filters && filters.promo_remainders)
        return `${config.public.icsBaseUrl}/service/public/car/${country.value}/promo/restposten/`;

    // Other filters (or no filters (except b2b/b2c))
    return `${config.public.icsBaseUrl}/service/public/cars/${country.value}/?${filterParams}${config.public.siteMode === 'B2B' ? '&b2b=1' : ''}`;
};
